import React, { useState } from "react";
import { useLanguage } from "../context/LanguageProvider";
import { useTranslation } from "react-i18next";

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const { currentLanguage, handleLanguageChange } = useLanguage();
  const [activeLanguage, setActiveLanguage] = useState(currentLanguage);

  const changeLanguage = (newLanguage) => {
    setActiveLanguage(newLanguage);
    handleLanguageChange(newLanguage);
    i18n.changeLanguage(newLanguage);
  };

  return (
    <div className="buttonsContainer">
      <div className="languageButtonsContainer">
        <button
          className={
            activeLanguage === "hu"
              ? "languageButton activeLanguageButton"
              : "languageButton"
          }
          onClick={() => changeLanguage("hu")}
        >
          HU
        </button>
        <button
          className={
            activeLanguage === "ro"
              ? "languageButton activeLanguageButton"
              : "languageButton"
          }
          onClick={() => changeLanguage("ro")}
        >
          RO
        </button>
      </div>
    </div>
  );
};

export default LanguageSelector;
