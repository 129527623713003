const quotesArray = [
    {
        id: 1,
        hu: "Akinek szívében Isten lakozik, mennyországot hord magában.",
        ro: "Cel care îl are pe Dumnezeu în inima lui, poartă Raiul în el.",
    },
    {
        id: 2,
        hu: "Boldog az, akinek elméje lehetőleg mindig Istenben és Isten mindig elméjében van.",
        ro: "Fericit este cel a cărui minte este mereu în Dumnezeu, și Dumnezeu este întotdeauna în mintea lui."
    },
    {
        id: 3,
        hu: "Azokat, akik az Urat teljes szívükből szeretik, minden segíti és minden javukra szolgál, hogy még több érdemet szerezzenek és még közelebb jussanak Istenhez s bensőbb szeretettel egyesüljenek Urukkal, Teremtőjükkel.",
        ro: "Pentru cei care iubesc pe Domnul din toată inima, totul îi ajută și totul slujește binele lor, pentru ca ei să dobândească și mai mult merit și să se apropie și mai mult de Dumnezeu și să se unească cu Domnul lor, Creatorul lor, cu dragoste interioară.",
    },
    {
        id: 4,
        hu: "Mi csak arra ügyeljünk, hogy Isten akaratát teljesítsük. Isten majd gondoskodik arról, amire szükségünk lesz.",
        ro: "Trebuie doar să ne asigurăm să împlinim voia lui Dumnezeu. Dumnezeu ne va oferi ceea ce avem nevoie.",
    },
    {
        id: 5,
        hu: "Aki Istent bírja, azt semmi szomorúság nem érheti, mert Istent saját akarata ellenére el nem veszítheti: ugyanis minden szomorúság valamely jó elvesztéséből, vagy annak elvesztésétől való félelemből származik.",
        ro: "Cel ce îndură pe Dumnezeu nu poate fi atins de nici o tristețe, pentru că nu poate să-L piardă pe Dumnezeu împotriva propriei sale voințe: căci toată tristețea vine din pierderea unui bine sau din frica de a-l pierde."
    },
    {
        id: 6,
        hu: "Bőkezű az Isten: az ő kezéből megkapom azt, amit az emberek kezében nem találok: ha ezek nem is adtak semmit, Istentől mindent megkapok.",
        ro: "Dumnezeu este darnic: din mâna Lui primesc ceea ce nu pot găsi în mâna oamenilor: chiar dacă ei nu mi-au dat nimic, primesc totul de la Dumnezeu.",
    },
    {
        id: 7,
        hu: "Egyetlen jogos ambíció, Istent szeretni és e szeretet jutalmául a szeretetben növekedni.",
        ro: "Singura ambiție legitimă este să-L iubești pe Dumnezeu și să crești în dragoste ca răsplată pentru această iubire."
    },
    {
        id: 8,
        hu: "Ha a szeretetben haladni akarsz, beszélj a szeretetről. Amint a szél a tüzet, úgy élesztik a jámbor beszélgetések a szeretetet.",
        ro: "Dacă vrei să progresezi în dragoste, vorbește despre dragoste. Așa cum vântul stârnește focul, la fel și conversațiile pioase însuflețesc dragostea."
    },
    {
        id: 9,
        hu: "Isten élesen látó pénzváltó, a szeretetet inkább tettek, mint szavak szerint értékeli.",
        ro: "Dumnezeu este un schimbător de bani cu o vedere ascuțită, el evaluează dragostea mai degrabă în fapte decât în cuvinte."
    },
    {
        id: 10,
        hu: "Minél reménytelenebbnek látszik a helyzet, annál jobban kell bízni Istenben: ahol kifogyott az emberi segítség, ott kezdődik az isteni.",
        ro: "Cu cât situația pare mai deznădăjduită, cu atât trebuie să avem mai multă încredere în Dumnezeu: acolo unde ajutorul uman s-a epuizat, începe ajutorul divin."
    },
    {
        id: 11,
        hu: "Oly nagy bizalmunk legyen Istenben, hogy azt is higgyük, hogy hajó híján egy szál deszkán is átszelhetjük a tengert.",
        ro: "Ar trebui să avem atât de mare încredere în Dumnezeu încât să credem și că, în absența unei corăbii, putem traversa marea pe o singură scândură."
    },
    {
        id: 12,
        hu: "Aki Isten szolgálatáért megfeledkezik magáról és saját hasznáról, a fölött Isten őrködik.",
        ro: "Cine uită de sine și de folosul său de dragul slujirii lui Dumnezeu, Dumnezeu îl veghează."
    },
    {
        id: 13,
        hu: "Teljesítsük kötelességünket tehetségünk szerint; a többit hagyjuk Istenre; az ő gondviselése mindenre kiterjed.",
        ro: "Să ne îndeplinim datoria conform talentului nostru; restul îl lăsăm lui Dumnezeu; providența lui este atotcuprinzătoare."
    },
    {
        id: 14,
        hu: "Annyi nyugalmat és örömöt fogunk egykor találni, amennyi jót tettünk az életben.",
        ro: "Vom găsi într-o zi la fel de multă pace și bucurie cât am făcut-o în viață."
    },
    {
        id: 15,
        hu: "Soha ne tégy, se ne beszélj semmit, míg előbb meg nem gondoltad, tetszik-e az Istennek, használ-e neked, épülésére válik-e embertársaidnak.",
        ro: "Niciodată să nu faci sau să spui nimic până nu te-ai gândit mai întâi dacă îi place lui Dumnezeu, dacă îți este de folos, dacă va fi o edificare pentru semenii tăi."
    },
    {
        id: 16,
        hu: "Minél inkább egyedül van a lelkünk és minél inkább elkülönül mindentől, annál alkalmasabb lesz arra, hogy Teremtőjéhez és Urához közeledjék és Ő elérje.",
        ro: "Cu cât sufletul nostru este mai singur și cu cât este mai despărțit de toate, cu atât mai potrivit va fi să se apropie de Creatorul și Domnul său și de a fi atins de El."
    },
    {
        id: 17,
        hu: "A henyélés, minden baj forrása, amennyire lehet, a mi házunkban helyet ne találjon.",
        ro: "Pe cât posibil, lenevia, sursa tuturor problemelor, nu ar trebui să-și găsească loc în casa noastră."
    },
    {
        id: 18,
        hu: "Azokra vár az égben a legértékesebb korona, akik minden munkájukat a tőlük telhető legnagyobb szorgalommal végzik. Nem elég ugyanis véghezvinni önmagában véve jó dolgokat, hanem jól is kell azokat végezni.",
        ro: "Cea mai valoroasă coroană așteaptă în ceruri pe cei care își fac toată munca cu cea mai mare sârguință. Nu este suficient să faci lucruri bune în sine, ci să le faci bine."
    },
    {
        id: 19,
        hu: "Egyetlen ritka és kiváló cselekedet sokkal többet ér ezer közönségesnél.",
        ro: "Un act rar și excelent valorează mult mai mult decât o mie de acțiuni comune."
    },
    {
        id: 20,
        hu: "Nem a sok tudás telíti és elégíti ki a lelket, hanem a dolgok benső lelki megérzése és megízlelése.",
        ro: "Nu cunoașterea multă saturează și satisface sufletul, ci simțirea interioară și gustarea spirituală a lucrurilor."
    },
    {
        id: 21,
        hu: "Bizonyos légy a felől, hogy Isten mindig kész bőkezűségre, csak mibennünk mélységes alázatosságot találjon.",
        ro: "Fii sigur că Dumnezeu este întotdeauna gata să fie generos, doar să găsească în noi smerenie profundă."
    },
    {
        id: 22,
        hu: "A hiúság és üres dicsvágy hibája tudatlanságból és vak önszeretetből származik.",
        ro: "Vina vanității și a ambiției goale vine din ignoranță și iubire de sine oarbă."
    },
    {
        id: 23,
        hu: "Aki a magasba akar repülni, annak nagyon mélyre kell leszállnia.",
        ro: "Cel care vrea să zboare sus trebuie să aterizeze foarte jos."
    },
    {
        id: 24,
        hu: "Győzd le magad, ha ugyanis magadat legyőzted, fényesebb koronát kapsz az égben, mint a szelídebb természetűek.",
        ro: "Cucerește-te pe tine însuți, căci dacă te-ai cucerit pe tine însuți, vei primi o coroană mai strălucitoare în cer decât cei de natură mai blândă."
    },
    {
        id: 25,
        hu: "Nehezebb a lelket megfékezni, mint a testet sanyargatni.",
        ro: "Este mai greu să înfrângi sufletul decât să chinui trupul."
    },
    {
        id: 26,
        hu: "Semmi piszkosat és rendetlent ne tűrj magadon, de kerüld a túlzott külső csínt is, mely elpuhultságról és hiúságról tanúskodik.",
        ro: "Nu suporta nimic murdar și dezordonat, dar evită și farsele exterioare excesive, care mărturisesc moliciune și vanitate."
    },
    {
        id: 27,
        hu: "Aki észreveszi, hogy hibázott, ne csüggedjen: a hibák is hasznunkra válhatnak.",
        ro: "Dacă observi că ai făcut o greșeală, nu te descuraja: greșelile pot fi și ele utile."
    },
    {
        id: 28,
        hu: "Bánatunk akkor igazi, ha szívünkben bánjuk bűneinket, szánkkal bevalljuk, cselekedetünkkel pedig elégtételt nyújtunk.",
        ro: "Pocăința noastră este reală atunci când regretăm păcatele în inimile noastre, le mărturisim cu gura și oferim împlinire prin acțiunile noastre."
    },
    {
        id: 29,
        hu: "Szeresd a legelvetemültebbeket is; szeresd bennük a maradék hitet Krisztusban, s ha ez nincs meg bennük, szeresd az erényeket, melyeknek híjával vannak; szeresd azt a szent képet, melyet viselnek; szeresd Krisztus vérét, mert hisz az váltotta meg őket.",
        ro: "Iubește chiar si pe cei mai depravați; iubiți în ei credința rămasă în Cristos și, dacă nu au aceasta, iubiți virtuțile care le lipsesc; iubiți chipul sfânt pe care îl poartă; iubiți sângele lui Cristos, pentru că i-a răscumpărat."
    },
    {
        id: 30,
        hu: "Nagy gonddal ügyeljetek arra, hogy egymásról jót gondoljatok s így egymást szeressétek.",
        ro: "Aveți mare grijă să vă gândiți bine unul la altul și astfel să vă iubiți."
    },
    {
        id: 31,
        hu: "Tartózkodni a civakodástól nemcsak nagylelkű és keresztény dolog, hanem egyúttal igen hasznos is.",
        ro: "A te abține de la ceartă nu este doar generoasă și creștină, ci și foarte utilă."
    },
    {
        id: 32,
        hu: "Ha az összes munkámmal legalább egy valakit rábírhatok arra, hogy akár csak egy éjjelen át is tartózkodjék az én Uramért, Jézusomért a bűntől, semmit el nem mulasztanék, hogy legalább ezen idő alatt ne bántsa meg az Istent, még ha tudnám is, hogy utána megint visszaesik bűnös szokásába.",
        ro: "Dacă, cu toată munca mea, aș putea convinge măcar o persoană să se abțină de la păcat chiar și pentru o noapte de dragul Domnului meu, Isus, n-aș eșua să fac nimic pentru ca măcar în acest timp să nu facă să-l jignească pe Dumnezeu, chiar dacă aș ști că atunci el cade din nou în obiceiul său păcătos."
    },
    {
        id: 33,
        hu: "Semmire sem szabad vállalkozni a nélkül, hogy előbb Istentől tanácsot ne kérnénk.",
        ro: "Nu ar trebui să întreprinzi nimic fără să-i ceri mai întâi sfatul lui Dumnezeu."
    },
    {
        id: 34,
        hu: "Úgy tegyünk mindent, hogy a dicséret keresése távol legyen minden cselekedetünktől: de olyan legyen minden tettünk, hogy abban semmit se lehessen joggal kifogásolni.",
        ro: "Să facem totul în așa fel încât căutarea laudei să fie departe de toate acțiunile noastre: dar toate acțiunile noastre să fie astfel încât nimic să nu poată fi obiectat pe drept."
    },
    {
        id: 35,
        hu: "Semmit sem szabad cselekednünk, vagy írnunk, amiből gyűlölség vagy keserűség származhat.",
        ro: "Nu trebuie să facem sau să scriem nimic care ar putea da naștere la ură sau amărăciune."
    },
    {
        id: 36,
        hu: "A tapasztalat arra tanít, hogy ahol sok az ellenmondás, ott nagyobb eredmény várható.",
        ro: "Experiența ne învață că acolo unde sunt multe contradicții, se așteaptă rezultate mai mari."
    },
    {
        id: 37,
        hu: "Ha észreveszed, hogy rosszakarók, vagy tudatlanok rágalmakkal halmoznak el, kérd az Istent, mentsen meg attól, hogy mikor rosszat mondanak rólad, egyszersmind igazat is mondjanak.",
        ro: "Dacă observi că nedoritorii sau ignoranții te defăimează, cere-i lui Dumnezeu să te salveze de faptul că atunci când spun lucruri rele despre tine, să spună și adevărul."
    },
    {
        id: 38,
        hu: "A súlyos zúgolódások ellen a legfőbb eszköz; kitartani kötelességeink hűséges teljesítésében és nagy adag türelemmel bírni.",
        ro: "Instrumentul principal împotriva murmurelor severe; să perseverăm în îndeplinirea fidelă a îndatoririlor noastre și să avem multă răbdare."
    },
    {
        id: 39,
        hu: "A másokon észrevett hiba - emberi dolog - tükör legyen számodra, melyből megláthatod, mily rútságot kell még magadról eltávolítanod.",
        ro: "Greșeala pe care o observi la ceilalți – un lucru uman – ar trebui să fie o oglindă pentru tine, din care să vezi ce urâțenie mai trebuie să îndepărtezi de la tine."
    },
    {
        id: 40,
        hu: "Aki biztosan akar az emberek között működni és forgolódni, az tartsa legfontosabbnak, hogy előzékeny legyen mindenkivel szemben, de lekötelezettje ne legyen senkinek.",
        ro: "Oricine dorește să lucreze și să circule cu siguranță printre oameni, ar trebui să considere că cel mai important e să fii politicos cu toată lumea, dar să nu fii dator cu nimeni."
    },
    {
        id: 41,
        hu: "Nemcsak Istenre kell tekintettel lennünk, hanem az emberekre is Isten miatt.",
        ro: "Nu trebuie să ne gândim doar la Dumnezeu, ci și la oameni de dragul lui Dumnezeu."
    },
    {
        id: 42,
        hu: "A gonoszakkal szemben úgy viselkedjél, mint ahogy a szerető anya betegen fekvő gyermekével; mennyire gyötrődik fia iránt érzett sajnálkozásában, mennyivel nagyobb gonddal és gyengédséggel ápolja, dédelgeti, mint az erőst és egészségest.",
        ro: "Poartă-te cu cei răi ca o mamă iubitoare cu copilul ei bolnav; cu cât de mult este chinuită de regretul pe care îl simte pentru fiul ei, cu atât mai multă grijă și tandrețe îl ajută și îl prețuiește, decât pe cel puternic și sănătos."
    },
    {
        id: 43,
        hu: "Ha olyasmit kérnek tőled, amit károsnak gondolsz, akkor ügyelj, hogy egyrészt a kérést tagadd meg, de másrészt a kérőt tartsd meg barátodnak.",
        ro: "Dacă vi se cere să faceți ceva care credeți că este dăunător, atunci asigurați-vă că refuzați cererea, pe de o parte, dar, pe de altă parte, mențineți persoana care cere ca prieten."
    },
    {
        id: 44,
        hu: "Inkább kéréssel és ajándékkal iparkodjuk elérni azt, amire törekszünk, mint civakodással.",
        ro: "Să încercăm să realizăm ceea ce ne dorim mai degrabă cu cereri și cadouri decât prin ceartă."
    },
    {
        id: 45,
        hu: "Az ügyek elintézésénél legyünk nagylelkűek az időre nézve, más szóval, ha valamit holnapra ígértünk és megtehetjük ma, tegyük meg ma.",
        ro: "Când ne ocupăm de chestiuni, să fim generoși cu timpul, cu alte cuvinte, dacă am promis ceva pentru mâine și putem face asta astăzi, să o facem azi."
    },
    {
        id: 46,
        hu: "(A társalgás célja társunk épülése.) Mind a rövidre szabott, mind a hosszúra nyúló társalgás árthat ennek; tehát kerülni kell mind a két túlhajtást s a középmértéket kell megtartani.",
        ro: "Scopul conversației este de a construi partenerul nostru.) Atât conversațiile scurte, cât și cele lungi pot dăuna acestui lucru; prin urmare, ambele excese trebuie evitate și trebuie păstrată măsura."
    },
    {
        id: 47,
        hu: "Némely esetben jobb hallgatni, mint beszélni: nincs szükség ott védőbeszédre, ahol az igazság a maga védője.",
        ro: "În unele cazuri, este mai bine să taci decât să vorbești: nu este nevoie de un discurs defensiv unde adevărul este propriul său apărător."
    },
    {
        id: 48,
        hu: "Sohase ígérj többet, mint amennyit meg tudsz tenni.",
        ro: "Nu promite niciodată mai mult decât poți face."
    },
    {
        id: 49,
        hu: "Hiábavaló szót ne mondjunk; ilyennek azt a szót tartom, amely sem nekem, sem másnak nem használ és nem is fakad ebből a szándékból.",
        ro: "Să nu spunem un cuvânt degeaba; consider un astfel de cuvânt care nu-mi folosește nici mie, nici oricui altcineva și nici nu se naște din această intenție."
    },
    {
        id: 50,
        hu: "A lelki haladásra nagyon hasznos, ha van barátod, akitől szívesen veszed, ha hibádra figyelmeztet.",
        ro: "Este foarte util pentru progresul spiritual dacă ai un prieten care te avertizează despre greșelile tale."
    },
    {
        id: 51,
        hu: "Mindenki azokat válassza követendő példaképül, akik buzgóságban és nagylelkűségben kitűnnek.",
        ro: "Fiecare ar trebui să aleagă drept modele de urmat pe cei care excelează în zel și generozitate."
    },
];

export default quotesArray;