import React from "react";
import LogoHu from "../assets/img/logo_hu.svg";
import LogoRo from "../assets/img/logo_ro.png";
import { useLanguage } from "../context/LanguageProvider";
import LanguageSelector from "./LanguageSelector";

const Header = () => {
  const { currentLanguage } = useLanguage();

  return (
    <div className="header">
      <img
        className="logo"
        src={currentLanguage === "hu" ? LogoHu : LogoRo}
        alt="logo"
      />
      <LanguageSelector />
    </div>
  );
};

export default Header;
