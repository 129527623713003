import React, { useState, useEffect } from "react";
import md5 from "md5";
import quotesArray from "../assets/data";
import { useLanguage } from "../context/LanguageProvider";
import { useTranslation } from "react-i18next";
let timeout;

const Input = () => {
  const { t } = useTranslation();
  const [input, setInput] = useState("");
  const [inputName, setInputName] = useState("");
  const [index, setIndex] = useState(0);
  const [name, setName] = useState("");
  const [quote, setQuote] = useState("");
  const [fadeOut, setFadeOut] = useState(false);

  const { currentLanguage } = useLanguage();

  const handleInputChange = (e) => {
    setInput(e.target.value);
    if (e.target.value !== "") {
      setInputName(e.target.value);
    }
  };

  const getCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  };

  const getMd5Hash = () => {
    const date = getCurrentDate();
    const inputString = `${inputName}${date}`;
    const hash = md5(inputString).toString();

    const last4Characters = hash.slice(-4);
    const decimalNumber = parseInt(last4Characters, 16);

    const remainder = decimalNumber % quotesArray.length;
    return remainder;
  };

  const getQuote = (quoteIndex) => {
    if (inputName !== "") {
      const generatedQuote = quotesArray[quoteIndex];

      if (currentLanguage === "hu") {
        setQuote(generatedQuote.hu);
      } else {
        setQuote(generatedQuote.ro);
      }

      setName(inputName);
      setInput("");
    }
  };

  const generateQuote = (e) => {
    e.preventDefault();
    if (input !== "" && inputName !== "") {
      setFadeOut(false);
      document.activeElement.blur();

      const quoteIndex = getMd5Hash();

      setIndex(quoteIndex);
      getQuote(quoteIndex);
    }
    console.log(timeout);
    clearTimeout(timeout);

    timeout = setTimeout(() => {
      handleInputClick();
    }, 30000);
    console.log(timeout);
  };

  useEffect(() => {
    getQuote(index);
  }, [currentLanguage]);

  const handleInputClick = () => {
    setFadeOut(true);
  };

  return (
    <>
      <form onSubmit={(e) => generateQuote(e)} spellCheck={false}>
        <div className="inputContainer">
          <input
            className="input"
            type="text"
            placeholder={t("inputPlaceholder")}
            value={input}
            onChange={(e) => handleInputChange(e)}
            maxLength={100}
            onClick={handleInputClick}
          />
          <button
            className={
              input !== ""
                ? "generateButton activeGenerateButton"
                : "generateButton"
            }
            type="submit"
          >
            {t("generateButton")}
          </button>
        </div>
      </form>
      {quote !== "" && (
        <div
          className={`quoteContainer ${
            input === "" ? "fade-in fade-in-active" : ""
          } ${fadeOut ? "fade-out fade-out-active" : ""}`}
        >
          <div className="welcomeText">
            <p>
              {t("dear")}
              {name}
              {t("yourQuoteIs")}
            </p>
          </div>
          <p className="quote">{quote}</p>
        </div>
      )}
    </>
  );
};

export default Input;
