import React, { useEffect } from 'react';
import './App.css';
import Input from './components/Input';
import { LanguageProvider } from './context/LanguageProvider';
import LanguageSelector from './components/LanguageSelector';
import Header from './components/Header';
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const handleTouchMove = (event) => {
      if (event.scale !== 1) {
        event.preventDefault();
      }
    };

    window.addEventListener('touchmove', handleTouchMove);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    const handleGestureStart = (e) => {
      e.preventDefault();
    };

    document.addEventListener('gesturestart', handleGestureStart);

    return () => {
      document.removeEventListener('gesturestart', handleGestureStart);
    };
  }, []);
  return (
    <LanguageProvider>
      <Header />
      <div className="App">
        <Input/>
      </div>
    </LanguageProvider>
  );
}

export default App;
