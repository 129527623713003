import i18next from "i18next";
import { initReactI18next } from "react-i18next";

i18next.use(initReactI18next).init({
    resources: {
        hu: {
            translation: {
                inputPlaceholder: "Írja be a nevét!",
                generateButton: "Mutasd az Ignáci Szikrám!",
                dear: "Kedves ",
                yourQuoteIs: ", az Ön mai Ignáci Szikrája a következő:"
            }
        },
        ro: {
            translation: {
                inputPlaceholder: "Scrie-ți numele!",
                generateButton: "Arată-mi Scânteia Ignațiană!",
                dear: "Dragă ",
                yourQuoteIs: ", Scânteia Dvs. Ignațiană de astăzi este următoarea:"
            }
        }
    },
    lng: "hu",
});

export default i18next;