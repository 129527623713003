import React, { createContext, useContext, useState } from "react";

const LanguageContext = createContext();

export const LanguageProvider=({ children }) =>{
  const [currentLanguage, setCurrentLanguage] = useState("hu");

  const handleLanguageChange = (language) => {
    setCurrentLanguage(language);
  };

  return (
    <LanguageContext.Provider value={{ currentLanguage, handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguage=()=> {
  return useContext(LanguageContext);
}
